<template>
  <nav class="navbar navbar-expand-lg fixed-top navbar-custom sticky">
    <div class="container">
      <a class="navbar-brand logo" href="index.html">
        <img src="../../assets/images/kurlabs-logo.png" width="30" height="30" alt="Kurlabs Logo" />
        <span>Kurlabs</span>
      </a>
      <button
        class="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarCollapse"
        aria-controls="navbarCollapse"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <i class="zmdi zmdi-menu"></i>
      </button>
      <div class="collapse navbar-collapse" id="navbarCollapse">
        <ul class="nav navbar-nav navbar-right ml-auto" id="mySidenav">
          <router-link tag="li" to="/" class="nav-item">
            <a class="nav-link">{{ $t("Home") }}</a>
          </router-link>

          <router-link tag="li" to="infrastructure" class="nav-item">
            <a class="nav-link">{{ $t("Infrastructure") }}</a>
          </router-link>

          <router-link tag="li" to="development" class="nav-item">
            <a class="nav-link">{{ $t("Development") }}</a>
          </router-link>

          <li class="nav-item dropdown">
            <a
              class="nav-link-block dropdown-toggle"
              href
              id="navbarDropdown"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >{{ $t("Products") }}</a>

            <ul class="dropdown-menu arrow" aria-labelledby="navbarDropdown">
              <li>
                <a class="dropdown-item" href="https://alerty.online/" target="_blank">Alerty</a>
              </li>
              <li>
                <a class="dropdown-item" href="https://www.tecu.co/" target="_blank">TECU</a>
              </li>
            </ul>
          </li>
          <li class="nav-item">
            <a href="https://erp.kurlabs.com/web" target="_blank" class="nav-link">ERP</a>
          </li>

          <ChangeLang />

          <li class="nav-item">
            <a
              href="https://ctl.kurlabs.com"
              target="_blank"
              class="btn btn-primary btn-shadow btn-rounded w-lg animated fadeInDown wow"
              data-wow-delay=".4s"
            >CTL - {{ $t("Sign In") }}</a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
import ChangeLang from "./ChangeLang.vue";
export default {
  name: "NavBar",
  components: {
    ChangeLang
  }
};
</script>

<style scoped></style>
