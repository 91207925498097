<template>
  <li class="nav-item dropdown">
    <a
      class="nav-link dropdown-toggle"
      href="#"
      id="navbarDropdown"
      role="button"
      data-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false"
    >
      <a v-if="$i18n.locale == 'es'">
        <img src="../../assets/images/lang/logo_es.png" alt="esp" />
        {{ $t("Spanish") }}
      </a>
      <a v-if="$i18n.locale == 'en'">
        <img src="../../assets/images/lang/logo_en.png" alt="eng" />
        {{ $t("English") }}
      </a>
    </a>
    <div class="dropdown-menu" aria-labelledby="navbarDropdown">
      <a class="dropdown-item" href="#" v-on:click="changeLang('en')">
        <img src="../../assets/images/lang/logo_en.png" alt="eng" />
        {{ $t("English") }}
      </a>
      <a class="dropdown-item" href="#" v-on:click="changeLang('es')">
        <img src="../../assets/images/lang/logo_es.png" alt="esp" />
        {{ $t("Spanish") }}
      </a>
    </div>
  </li>
</template>
<script>
export default {
  name: "ChangeLang",
  methods: {
    changeLang: function(lang) {
      this.$i18n.locale = lang;
    }
  }
};
</script>

<style scoped>
a {
  font-size: 0.9rem !important;
}
</style>