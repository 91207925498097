<template>
  <div id="app">
    <NavBar />

    <router-view></router-view>

    <FooterKurlabs />
  </div>
</template>

<script>
import "bootstrap";
// Jquery easing
//import "./assets/js/jquery.easing.min.js";
import "jquery";
//import "./assets/js/owl.carousel.min.js";
//sticky header
//import "./assets/js/jquery.sticky.js";
//common script for all pages
import "./assets/js/jquery.app.js";

import NavBar from "./components/base/NavBar.vue";
import FooterKurlabs from "./components/base/FooterKurlabs.vue";
import i18n from "./i18n/";

export default {
  mounted() {
    let lang = document.getElementsByTagName("html")[0].getAttribute("lang");
    i18n.locale = lang;
  },

  name: "app",
  components: {
    NavBar,
    FooterKurlabs
  }
};
</script>
