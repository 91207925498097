export default {
    "/development": "/development",
    "/infrastructure": "/infrastructure",
    "development": "development",
    "infrastructure": "infrastructure",
    "Infrastructure": "Infrastructure",
    "Portfolio": "Portfolio",
    "en": "English",
    "es": "Spanish",
    "English": "English",
    "Spanish": "Spanish",
    "FOLLOW US": "FOLLOW US",
    "All rights reserved": "All rights reserved",
    "Home": "Home",
    "Sign In": "Sign In",
    "Products": "Products",
    "Our Clients love us!": "Our Clients love us!",
    "We use the most innovative technologies":
        "We use the most innovative technologies",
    "Their experience in complex topics like kubernetes autoscaling have been vital for our organization":
        "Their experience in complex topics like kubernetes autoscaling have been vital for our organization",
    "Robust technology for our services, we are sure that nothing has failed in several months, it is fast and pleasant to use":
        "Robust technology for our services, we are sure that nothing has failed in several months, it is fast and pleasant to use",
    "Drop us a line or two": "Drop us a line or two",
    "We'd love to hear from you": "We'd love to hear from you",
    "Please correct the following error (s):":
        "Please correct the following error (s):",
    "Name": "Name",
    "Email": "Email",
    "Message": "Message",
    "Please enter message": "Please enter message",
    "Submit": "Submit",
    "Administration,development": "Administration,development",
    "and deployment.": "and deployment.",
    "We guarantee excellent service at any stage.": "We guarantee excellent service at any stage.",
    "Get Started": "Get Started",
    "Our clients make a request.": "Our clients make a request.",
    "Support reports the request to the project manager.": "Support reports the request to the project manager.",
    "The director transmits the request to the development team.": "The director transmits the request to the development team.",
    "The Development Team encodes the best request.": "The Development Team encodes the best request.",
    "The code is uploaded to gitlab.": "The code is uploaded to gitlab.",
    "Gitlab automatically runs the tests and builds a new image.": "Gitlab automatically runs the tests and builds a new image.",
    "When passing the tests, the new code in production is displayed.": "When passing the tests, the new code in production is displayed.",
    "The end user can see the changes made.": "The end user can see the changes made.",
    "You're here because you want the best": "You're here because you want the best",
    "And we know it": "And we know it",
    "CI/CD for your application": "CI/CD for your application",
    "We create all environment for create containers automatically when you push a commit of your code.":
        "We create all environment for create containers automatically when you push a commit of your code.",
    "Kurlabs CTL - Powerful and unified interface": "Kurlabs CTL - Powerful and unified interface",
    "We provide you an interface for monitor, access, and manage your application in the servers. With our Kurlabs CTL system it's possible!":
        "We provide you an interface for monitor, access, and manage your application in the servers. With our Kurlabs CTL system it's possible!",
    "Alerting": "Alerting",
    "You could have a new email when the applications is down.": "You could have a new email when the applications is down.",
    "One platform": "One platform",
    "To rule them all": "To rule them all",
    "Backend development": "Backend development",
    "Kurlabs has a large backend development team, with experience in the development of high-level computing platforms and with high concurrency support.":
        "Kurlabs has a large backend development team, with experience in the development of high-level computing platforms and with high concurrency support.",
    "To achieve this goal, Kurlabs has experts in Django, a Web framework with Python programming language and Go experts, a programming language created by google which is used for the development of high concurrency microservices."
        : "To achieve this goal, Kurlabs has experts in Django, a Web framework with Python programming language and Go experts, a programming language created by google which is used for the development of high concurrency microservices.",
    "These systems are integrated into modern, relational databases such as MySql, Postgres and non-relational databases such as MongoDB.":
        "These systems are integrated into modern, relational databases such as MySql, Postgres and non-relational databases such as MongoDB.",
    "Additionally, the team has experience in connections to any external service or platform via API.":
        "Additionally, the team has experience in connections to any external service or platform via API.",
    "We commit": "We commit",
    "In all projects we are committed to the evolution and growth of the software, that is, we make all the changes, corrections and new functionalities with the same methodology and effort of the initial project, we believe that the software will always require changes as our clients grow, For this reason we commit ourselves to be an ally in the development of the project and in future updates."
        : "In all projects we are committed to the evolution and growth of the software, that is, we make all the changes, corrections and new functionalities with the same methodology and effort of the initial project, we believe that the software will always require changes as our clients grow, For this reason we commit ourselves to be an ally in the development of the project and in future updates.",
    "Development": "Development",
    "We are a company focused on the development of cloud technologies, we offer computing services in providers such as Google Compute Platform, AWS and Azure, which range from the administration of third-party platforms to the development of self-scalable systems.":
        "We are a company focused on the development of cloud technologies, we offer computing services in providers such as Google Compute Platform, AWS and Azure, which range from the administration of third-party platforms to the development of self-scalable systems.",
    "Frontend development": "Frontend development",
    "For frontend development we have modern, powerful, stable and scalable tools, through which we seek to provide communication solutions with the least possible friction between the client and the application.":
        "For frontend development we have modern, powerful, stable and scalable tools, through which we seek to provide communication solutions with the least possible friction between the client and the application.",
    "We mainly use React and Vue as JS frameworks, as well as experts in the field that are creating and embodying customized solutions for each project.":
        "We mainly use React and Vue as JS frameworks, as well as experts in the field that are creating and embodying customized solutions for each project.",
    "For projects that do not need a complex Backend service we offer integration with Firebase, through which we have databases, authentication, mail delivery functions among others.":
        "For projects that do not need a complex Backend service we offer integration with Firebase, through which we have databases, authentication, mail delivery functions among others.",
    "Initial setup": "Initial setup",
    "The initial configuration consists of the purchase and configuration of domains and external platforms for the development of the project, which we can develop internally or give the client instructions for him to do it, An example of these configurations are: email services like Gmai (Gsuite), Zoho or Hotmail.":
        "The initial configuration consists of the purchase and configuration of domains and external platforms for the development of the project, which we can develop internally or give the client instructions for him to do it, An example of these configurations are: email services like Gmai (Gsuite), Zoho or Hotmail.",
    "This process also includes the configuration of the continuous integration service (CI / CD), which consists of automating the test stages and deployment of new code automatically, using tools such as Gitlab, Github, Rancher, Kubernetes, AWS, GCP, among others.":
        "This process also includes the configuration of the continuous integration service (CI / CD), which consists of automating the test stages and deployment of new code automatically, using tools such as Gitlab, Github, Rancher, Kubernetes, AWS, GCP, among others.",
    "KURLABS MONITORING - ALERTY": "KURLABS MONITORING - ALERTY",
    "Regular and constant monitoring of web services.": "Regular and constant monitoring of web services.",
    "SMS, email and Slack notifications.": "SMS, email and Slack notifications.",
    "Generation of service activity reports.": "Generation of service activity reports.",
    "Monitor JS elements, css and images.": "Monitor JS elements, css and images.",
    "Benefits": "Benefits",
    "It reduces the cost, time and risk of making changes by allowing more updates to applications in testing and production.":
        "It reduces the cost, time and risk of making changes by allowing more updates to applications in testing and production.",
    "Construction, testing and software release in shorter times and more frequently.":
        "Construction, testing and software release in shorter times and more frequently.",
    "A simple and repeatable deployment process for continuous delivery.":
        "A simple and repeatable deployment process for continuous delivery.",
    "Reduction of service downtime between new version deployments.":
        "Reduction of service downtime between new version deployments.",
    "Infrastructure and": "Infrastructure and",
    "Support": "Support",
    "We want to become a faithful ally of our clients for the correct operation and operations of their information systems.":
        "We want to become a faithful ally of our clients for the correct operation and operations of their information systems.",
    "KURLABS MANAGER CONTROL": "KURLABS MANAGER CONTROL",
    "Global visualization of services in test and production environments.":
        "Global visualization of services in test and production environments.",
    "Simple and fast application administration.": "Simple and fast application administration.",
    "Improves monitoring and inspection of application records.": "Improves monitoring and inspection of application records.",
    "It allows the display of features such as: RAM, CPU and Network by service.":
        "It allows the display of features such as: RAM, CPU and Network by service.",
    "Administration of the application, in a graphic and intuitive way.":
        "Administration of the application, in a graphic and intuitive way.",
    "Visualization of the resources used by the applications (CPU, RAM, Network).":
        "Visualization of the resources used by the applications (CPU, RAM, Network).",
    "Log access without entering the server.": "Log access without entering the server.",
    "Access to shell console without entering the server.": "Access to shell console without entering the server.",
    "Reports": "Reports",
    "A report will be delivered monthly where you can see the possible incidents of the service, the performance of the dashboard (Manager), the performance of the scripst, among other important data.":
        "A report will be delivered monthly where you can see the possible incidents of the service, the performance of the dashboard (Manager), the performance of the scripst, among other important data.",
    "Email is required.": "Email is required.",
    "The message is required.": "The message is required."
};
