import Vue from "vue";
import App from "./App.vue";
import "bootstrap/dist/css/bootstrap.min.css";
//Animate
import "./assets/css/animate.css";
//Icon-font
import "./assets/css/material-design-iconic-font.min.css";
//Color css
import "./assets/css/colors/default.css";
//Custom styles for this template
import "./assets/css/style.css";
import "./assets/css/app.css";
import router from "./router";
import i18n from "./i18n";

Vue.config.productionTip = false;

new Vue({
  router,
  i18n,
  render: h => h(App)
}).$mount("#app");
