import Vue from "vue";
import VueI18n from "vue-i18n";
import langEsp from './es/index';
import langEng from './en/index';

Vue.use(VueI18n);

const messages = {
  en: langEng,
  es: langEsp
};

const i18n = new VueI18n({
  locale: "en", // set locale
  fallbackLocale: "es", // set fallback locale
  messages // set locale messages
});

export default i18n;
