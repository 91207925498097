export default {
    "/development": "/desarrollo",
    "/infrastructure": "/infraestructura",
    "development": "desarrollo",
    "infrastructure": "infraestructura",
    "Infrastructure": "Infraestructura",
    "Portfolio": "Portafolio",
    "en": "Ingles",
    "es": "Español",
    "English": "Ingles",
    "Spanish": "Español",
    "FOLLOW US": "SÍGUENOS",
    "All rights reserved": "Todos los derechos reservados",
    "Home": "Inicio",
    "Sign In": "Ingresar",
    "Products": "Productos",
    "Our Clients love us!": "¡Nuestros clientes nos aman!",
    "We use the most innovative technologies":
        "Utilizamos las tecnologías más innovadoras",
    "Their experience in complex topics like kubernetes autoscaling have been vital for our organization":
        "Su experiencia en temas complejos como el escalado automático de kubernetes ha sido vital para nuestra organización",
    "Robust technology for our services, we are sure that nothing has failed in several months, it is fast and pleasant to use":
        "Tecnología robusta para nuestros servicios, estamos seguros de que nada ha fallado en varios meses, es rápido y agradable de usar",
    "Drop us a line or two": "Déjanos una o dos líneas",
    "We'd love to hear from you": "Nos encantaría saber de ti",
    "Please correct the following error (s):":
        "Por favor corrige los siguientes errore(s):",
    "Name": "Nombre",
    "Email": "Correo electrónico",
    "Message": "Mensaje",
    "Please enter message": "Por favor ingrese el mensaje",
    "Submit": "Enviar",
    "Administration,development": "Administración, desarrollo",
    "and deployment.": "y despliegue.",
    "We guarantee excellent service at any stage.": "Le garantizamos un excelente servicio en cualquier etapa.",
    "Get Started": "Empezar",
    "Our clients make a request.": "Nuestras clientes hacen una solicitud.",
    "Support reports the request to the project manager.": "El soporte informa la solicitud al gerente del proyecto.",
    "The director transmits the request to the development team.": "El director transmite la solicitud al equipo de desarrollo.",
    "The Development Team encodes the best request.": "El equipo de desarrollo codifica la mejor solicitud.",
    "The code is uploaded to gitlab.": "El código se actualiza en gitlab.",
    "Gitlab automatically runs the tests and builds a new image.": "Gitlab ejecuta automáticamente las pruebas y crea una nueva imagen.",
    "When passing the tests, the new code in production is displayed.": "Al pasar las pruebas, se despliega el nuevo código en producción.",
    "The end user can see the changes made.": "El usuario final puede ver los cambios realizados.",
    "You're here because you want the best": "Estas aqui porque quieres la mejor",
    "And we know it": "Y lo sabemos",
    "CI/CD for your application": "CI/CD para su aplicación",
    "We create all environment for create containers automatically when you push a commit of your code.":
        "Creamos todo el entorno para crear contenedores automáticamente cuando empuja una confirmación de su código.",
    "Kurlabs CTL - Powerful and unified interface": "Kurlabs CTL: interfaz potente y unificada",
    "We provide you an interface for monitor, access, and manage your application in the servers. With our Kurlabs CTL system it's possible!":
        "Le proporcionamos una interfaz para monitorear, acceder y administrar su aplicación en los servidores. ¡Con nuestro sistema Kurlabs CTL es posible!",
    "Alerting": "Alertando",
    "You could have a new email when the applications is down.": "Podría tener un nuevo correo electrónico cuando las aplicaciones estén inactivas.",
    "One platform": "Una plataforma",
    "To rule them all": "Para gobernarlas a todas",
    "Backend development": "Desarrollo de backend",
    "Kurlabs has a large backend development team, with experience in the development of high-level computing platforms and with high concurrency support.":
        "Kurlabs tiene un gran equipo de desarrollo de back-end, con experiencia en el desarrollo de plataformas informáticas de alto nivel y con un alto soporte de concurrencia.",
    "To achieve this goal, Kurlabs has experts in Django, a Web framework with Python programming language and Go experts, a programming language created by google which is used for the development of high concurrency microservices."
        : "Para lograr este objetivo, Kurlabs tiene expertos en Django, un framework del lenguaje de programación Python. Expertos en Go, un lenguaje de programación creado por google que se utiliza para el desarrollo de microservicios de alta concurrencia.",
    "These systems are integrated into modern, relational databases such as MySql, Postgres and non-relational databases such as MongoDB.":
        "Estos sistemas están integrados en bases de datos relacionales modernas como MySql, Postgres y bases de datos no relacionales como MongoDB.",
    "Additionally, the team has experience in connections to any external service or platform via API.":
        "Además, el equipo tiene experiencia en conexiones a cualquier servicio externo o plataforma a través de API.",
    "We commit": "Nos comprometemos",
    "In all projects we are committed to the evolution and growth of the software, that is, we make all the changes, corrections and new functionalities with the same methodology and effort of the initial project, we believe that the software will always require changes as our clients grow, For this reason we commit ourselves to be an ally in the development of the project and in future updates."
        : "En todos los proyectos estamos comprometidos con la evolución y el crecimiento del software, es decir, hacemos todos los cambios, correcciones y nuevas funcionalidades con la misma metodología y esfuerzo del proyecto inicial, creemos que el software siempre requerirá cambios como nuestro los clientes crecen, por este motivo nos comprometemos a ser un aliado en el desarrollo del proyecto y en futuras actualizaciones.",
    "Development": "Desarrollo",
    "We are a company focused on the development of cloud technologies, we offer computing services in providers such as Google Compute Platform, AWS and Azure, which range from the administration of third-party platforms to the development of self-scalable systems.":
        "Somos una empresa enfocada en el desarrollo de tecnologías en la nube, ofrecemos servicios informáticos en proveedores como Google Compute Platform, AWS y Azure, que van desde la administración de plataformas de terceros hasta el desarrollo de sistemas autoescalables.",
    "Frontend development": "Desarrollo frontend",
    "For frontend development we have modern, powerful, stable and scalable tools, through which we seek to provide communication solutions with the least possible friction between the client and the application.":
        "Para el desarrollo frontend contamos con herramientas modernas, potentes, estables y escalables, a través de las cuales buscamos proporcionar soluciones de comunicación con la menor fricción posible entre el cliente y la aplicación.",
    "We mainly use React and Vue as JS frameworks, as well as experts in the field that are creating and embodying customized solutions for each project.":
        "Utilizamos principalmente React y Vue como frameworks JS, así como expertos en el campo que están creando e incorporando soluciones personalizadas para cada proyecto.",
    "For projects that do not need a complex Backend service we offer integration with Firebase, through which we have databases, authentication, mail delivery functions among others.":
        "Para proyectos que no necesitan un servicio Backend complejo, ofrecemos integración con Firebase, a través del cual tenemos bases de datos, autenticación, funciones de entrega de correo, entre otros.",
    "Initial setup": "Configuración inicial",
    "The initial configuration consists of the purchase and configuration of domains and external platforms for the development of the project, which we can develop internally or give the client instructions for him to do it, An example of these configurations are: email services like Gmai (Gsuite), Zoho or Hotmail.":
        "La configuración inicial consiste en la compra y configuración de dominios y plataformas externas para el desarrollo del proyecto, que podemos desarrollar internamente o darle instrucciones al cliente para que lo haga. Un ejemplo de estas configuraciones son: servicios de correo electrónico como Gmai (Gsuite ), Zoho o Hotmail.",
    "This process also includes the configuration of the continuous integration service (CI / CD), which consists of automating the test stages and deployment of new code automatically, using tools such as Gitlab, Github, Rancher, Kubernetes, AWS, GCP, among others.":
        "Este proceso también incluye la configuración del servicio de integración continua (CI / CD), que consiste en automatizar las etapas de prueba y la implementación de nuevo código automáticamente, utilizando herramientas como Gitlab, Github, Rancher, Kubernetes, AWS, GCP, entre otras.",
    "KURLABS MONITORING - ALERTY": "MONITOREO DE KULLABS - ALERTY",
    "Regular and constant monitoring of web services.": "Monitoreo regular y constante de servicios web.",
    "SMS, email and Slack notifications.": "Notificaciones SMS, correo electrónico y Slack.",
    "Generation of service activity reports.": "Generación de informes de actividad del servicio.",
    "Monitor JS elements, css and images.": "Monitorear elementos JS, CSS e imágenes.",
    "Benefits": "Beneficios",
    "It reduces the cost, time and risk of making changes by allowing more updates to applications in testing and production.":
        "Reduce el costo, el tiempo y el riesgo de realizar cambios al permitir más actualizaciones a las aplicaciones en pruebas y producción.",
    "Construction, testing and software release in shorter times and more frequently.":
        "Construcción, prueba y lanzamiento de software en tiempos más cortos y con mayor frecuencia.",
    "A simple and repeatable deployment process for continuous delivery.":
        "Un proceso de implementación simple y repetible para entrega continua.",
    "Reduction of service downtime between new version deployments.":
        "Reducción del tiempo de inactividad del servicio entre implementaciones de nuevas versiones.",
    "Infrastructure and": "Infraestructura y",
    "Support": "Suporte",
    "We want to become a faithful ally of our clients for the correct operation and operations of their information systems.":
        "Queremos convertirnos en un fiel aliado de nuestros clientes para el correcto funcionamiento y las operaciones de sus sistemas de información.",
    "KURLABS MANAGER CONTROL": "KURLABS MANAGER CONTROL",
    "Global visualization of services in test and production environments.":
        "Visualización global de servicios en entornos de prueba y producción.",
    "Simple and fast application administration.": "Administración de aplicaciones simple y rápida.",
    "Improves monitoring and inspection of application records.": "Mejora el monitoreo y la inspección de los registros de la aplicación.",
    "It allows the display of features such as: RAM, CPU and Network by service.":
        "Permite la visualización de características tales como: RAM, CPU y red por servicio.",
    "Administration of the application, in a graphic and intuitive way.":
        "Administración de la aplicación, de forma gráfica e intuitiva.",
    "Visualization of the resources used by the applications (CPU, RAM, Network).":
        "Visualización de los recursos utilizados por las aplicaciones (CPU, RAM, Red).",
    "Log access without entering the server.": "Acceda a log sin ingresar al servidor.",
    "Access to shell console without entering the server.": "Acceso a la consola shell sin ingresar al servidor.",
    "Reports": "Reportes",
    "A report will be delivered monthly where you can see the possible incidents of the service, the performance of the dashboard (Manager), the performance of the scripst, among other important data.":
        "Se entregará un informe mensual donde podrá ver los posibles incidentes del servicio, el rendimiento del panel de control (Manager), el rendimiento del scripst, entre otros datos importantes.",
    "Email is required.": "Correo electronico es requerido.",
    "The message is required.": "El mensaje es requerido."



};
