<template>
  <div>
    <footer class="section bg-darkslategray footer">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-md-3">
            <div class="float-left pull-none">
              <a class="navbar-brand logo" href="index.html">
                <img
                  src="../../assets/images/kurlabs-logo-full.png"
                  alt="Kurlabs Logo"
                  height="40"
                />
              </a>
            </div>
          </div>
          <div class="col-md-6 text-center">
            <h4 class="mb-3 text-muted">{{ $t("FOLLOW US") }}</h4>
            <div class="pull-none">
              <a class="level-item" href="https://github.com/Kurlabs">
                <span class="icon">
                  <i class="fa fa-github"></i>
                </span>
              </a>
              <a
                class="level-item"
                href="https://www.facebook.com/JuanPabloAriasV"
              >
                <span class="icon">
                  <i class="fa fa-facebook"></i>
                </span>
              </a>
              <a class="level-item" href="https://twitter.com/JuanPaAriasV">
                <span class="icon">
                  <i class="fa fa-twitter"></i>
                </span>
              </a>
            </div>
          </div>
          <div class="col-md-3">
            <div class="float-right pull-none">
              <p class="pull-right text-muted m-b-0">
                © 2020 - {{ $t("All rights reserved") }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
    <a href="#" class="back-to-top">
      <i class="zmdi zmdi-chevron-up"></i>
    </a>
  </div>
</template>

<script>
export default {
  name: "FooterKurlabs"
};
</script>
