import Vue from "vue";
import Router from "vue-router";
import i18n from "../i18n/index";

const routerOptions = [
  {
    path: "/",
    component: "landing/LandingIndex",
    alias: ""
  },
  {
    path: i18n.t("/development"),
    component: "development/DevelopmentIndex"
  },
  {
    path: i18n.t("/infrastructure"),
    component: "infrastructure/InfrastructureIndex"
  },
  {
    path: "*",
    redirect: "/"
  }
];

const routes = routerOptions.map(route => {
  return {
    ...route,
    component: () => import(`../components/${route.component}.vue`)
  };
});

Vue.use(Router);

const router = new Router({
  mode: "hash", //mode: 'history',
  routes,
  //linkActiveClass: "active", // active class for non-exact links.
  linkExactActiveClass: "active" // active class for *exact* links.
});

export default router;
